@import "common.css";

.header-style2 {
	&.no-fix {
		.mainHeader {
			position: absolute !important;
		}
	}
}

.unit-view {

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.flow-buttons {
		.btn {
			padding: 10px 18px;

			&.btn-go {
				margin-left: 15px;
				margin-bottom: 20px;
			}
		}
	}

	.subrow {
		.book-btn {
			&.btn-lg {
				height: 40px;
				font-size: 16px;
			}
		}
	}

	.ratings {
		.stars {
			margin-top: 0;
			color: var(--primary);
			display: inline-flex;

			.fa {
				color: var(--primary);
			}

		}

		.rating-gr-bl {
			background: rgb(242, 246, 248);
			background: linear-gradient(331deg, rgba(242, 246, 248, 1) 0%, rgba(234, 238, 241, 1) 100%);
			box-shadow: 0 0 8px rgba(0, 0, 0, .15);
			max-width: 460px;
			margin-bottom: 15px;

			.rating-head {
				text-align: center;
				padding: 30px 20px;
				margin-bottom: 10px;

				h3 {
					margin-top: 0;
					font-family: var(--d-font2);
					font-weight: normal;
					font-size: 24px;
				}

				.rating-st-bl {
					display: inline-flex;
					max-width: 200px;
					text-align: center;
					border-radius: 20px;
					padding: 8px 20px;
					background-color: var(--white);
					font-size: 18px;
					font-family: var(--d-font2);
					margin: 15px 0 0;

					.stars {
						font-size: 16px;
						margin-top: 0;
					}

					span {
						margin-right: 10px;
					}
				}

				.blue-link {
					color: var(--secondary);
					text-decoration: underline;
				}
			}

			.rating-row {
				padding: 18px 20px;
				border-top: 2px solid #e2e6e8;

				span {
					font-size: 18px;
					font-family: var(--d-font2);
				}

				.stars {
					font-size: 16px;
				}
			}
		}

		.feedbacks {
			blockquote {
				p {
					font-family: 'Indie Flower', cursive;
				}

				footer {
					&:before {
						content: "-";
					}
				}
			}

			.rating-head {
				.stars {
					color: var(--primary);
					display: inline-block;
					margin-left: 15px;
				}
			}
		}


		.cl-feed-bl {
			@media (min-width:600px) {
				max-height: 220px;
				overflow: hidden;
			}
		}

		.cl-feedbl {
			box-shadow: 0 0 8px rgba(0, 0, 0, .15);
			padding: 20px;
			border-radius: 15px;
			background-color: var(--white);
			position: relative;
			min-height: 355px;

			@media (max-width:992px) {
				padding: 20px 30px;
				padding-top: 30px;
			}

			footer {
				font-size: 14px;
				position: absolute;
				bottom: 20px;
				color: var(--grey3);
			}

			i {
				font-size: 40px;
				position: absolute;
				color: var(--grey);
				top: -25px;
			}

			.cli-name {
				color: var(--secondary);
				font-family: var(--d-font2);
				font-weight: normal;
			}
		}
	}


	.search-section {
		margin: 0 -10px;

		.searchbox {
			padding: 0 10px;
			position: relative;

			i {
				position: absolute;
				right: 17px;
				top: 0;
				opacity: .2;
				font-size: 20px;
			}
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}

	.unit-icons {
		span {
			background-image: url(RESOURCE/img/unit-icons.png);
			width: 36px;
			height: 36px;
			display: inline-block;
			border-radius: 50%;
			margin-right: 8px;
			background-repeat: no-repeat;
			display: inline-block;
			background-color: #9D9D9D;
			margin-bottom: 5px;

			&.pets-cross {
				background-position: -253px -1px;
			}

			&.smoke {
				background-position: -304px -1px;
			}

			&.balcony {
				background-position: -122px -1px;
			}

			&.washing {
				background-position: -76px -1px;
			}

			&.pool {
				background-position: 0 0;
			}

			&.wlan {
				background-position: -24px 3px;
				background-size: 405px;
			}

			&.spulmachine {
				background-position: -350px 0px;
			}

			&.oven {
				background-position: 8px -2px;
			}

			&.shower {
				background-position: -192px -5px;
				background-size: 565px;
			}

			&.bathtub {
				background-position: 0 0;
			}

			&.pets {
				background-image: url(RESOURCE/img/unit-icons2-new.png);
				background-position: -44px 2px;
			}

			&.sauna {
				background-image: url(RESOURCE/img/unit-icons2-new.png);
				background-position: -133px 3px;
			}

			&.terrace {
				background-image: url(RESOURCE/img/unit-icons2-new.png);
				background-position: -89px 1px;
			}

			&.swimming {
				background-image: url(RESOURCE/img/unit-icons2-new.png);
				background-position: 3px 0px;
			}

			&.beachchair {
				background-position: -398px -1px;
			}

			&.seeview {
				background-position: -456px 0px;
			}
		}
	}

	#booking {
		padding: 15px;
		box-shadow: 0 0 10px rgba(0, 0, 0, .15);

		h2 {
			margin-top: 0;
			font-size: 24px;
			padding-bottom: 15px;
			text-align: center;
			border-bottom: 1px solid #f2f2f2;
			color: var(--secondary);
			font-weight: 600;
			text-transform: capitalize;
			margin: 0 -15px 20px;
			padding-top: 5px;
			padding-bottom: 16px;

			@media (max-width:992px) {
				font-size: 18px;
			}
		}

		.form-control {
			border: none;
			border-bottom: 2px solid #f2f2f2;
			text-transform: uppercase;
			margin-bottom: 30px;
			border-radius: 0;
			box-shadow: inherit;
			padding: 0;
		}


		@media (max-width:992px) {
			margin-bottom: 40px;
		}

	}

	.unit-list-section {
		.owl-item {
			position: relative;

			&:before {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(255, 255, 255, .7);
				content: ' ';
				transition: all 0.5s ease;
			}

			&.active {
				&:before {
					background-color: rgba(255, 255, 255, 0);
				}
			}
		}

		&.nxt-prev-btns {
			position: relative;

			.owl-nav {
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;



				@media (max-width:992px) {
					display: none;
				}


				button {
					width: 70px;
					height: 70px;
					border-radius: 50%;
					border: 1px solid #ddd !important;
					border-radius: 50%;
					box-shadow: 0 0 10px rgba(0, 0, 0, .5);
					background-color: var(--white) !important;
					position: absolute;
					left: 50px;
					font-size: 34px;

					span {
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						color: transparent;

						&:before {
							color: var(--black);
							font-family: "Font Awesome 5 Pro";
							position: relative;
							left: 3px;
						}
					}

					&:hover {
						span {
							opacity: .3;
						}
					}

					&.owl-next {
						position: absolute;
						right: 50px;
						left: initial;

						span {

							&:before {
								content: "\f054";
								left: 8px;
							}
						}
					}

					&.owl-prev {
						span {

							&:before {
								content: "\f053";

							}
						}
					}
				}
			}
		}

		.item {
			overflow: hidden;
			min-height: 860px;
			height: calc(89vh - 80px);

			@media (max-width:992px) {
				min-height: 736px;
			}

			@media (max-width:767px) {
				min-height: 505px;
			}



			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	.title-img {

		position: relative;
		width: 100%;
		height: 450px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}





	.main-section {
		position: relative;
		padding: 0;

		.map-strip {
			@media (min-width:993px) {
				min-height: 600px;
			}

			.time-li {
				@media (min-width:993px) {
					min-height: 420px;
				}
			}
		}

		.page-header {
			margin-top: 0;
			border-bottom: none;
			margin-bottom: 0;
			position: relative;

			.unit-fav-star {
				position: absolute;
				left: 0;
				top: -90px;
				z-index: 10;
				max-width: 450px;
				width: 100%;

				@media (max-width:992px) {
					width: 100%;
					display: flex;
					justify-content: space-between;
					top: -100px;
				}


				.stars {
					font-size: 18px;
					color: var(--primary);
					margin-right: 5px;
					letter-spacing: 2px;
					display: inline-block;

					@media (max-width:992px) {
						font-size: 13px;

					}
				}

				.btn-icon-fav {
					max-width: 280px;
					display: inline-block;
					background-color: rgba(255, 255, 255, .7);
					border-radius: 30px;
					font-size: 18px;
					border: none;
					padding-top: 10px;
					font-family: var(--d-font2);

					@media (max-width:992px) {
						font-size: 16px;
						padding-bottom: 10px;
						padding-top: 8px;
						max-width: 240px;
					}

					@media (max-width:360px) {
						font-size: 13px;
						max-width: 186px;
					}


					i {
						font-size: 22px;
						margin-right: 10px;
						color: var(--black);
						position: relative;
						top: 1px;

						@media (max-width:360px) {
							font-size: 16px;
							margin-right: 4px;
						}

					}

					&:hover {
						background-color: var(--white);
						color: var(--black);
					}
				}
			}



			@media (max-width:992px) {
				margin-top: 10px;
			}

			.heading1 {
				margin-bottom: 10px;
			}

			.unit-pr-dt-gallery {
				@media (min-width:993px) {
					display: flex;
					justify-content: space-between;

				}
			}

			.unit-dt-row {
				@media (min-width:1300px) {
					right: -45px;
				}

				@media (min-width:993px) {
					display: flex;
					justify-content: space-between;
					position: relative;
					right: -25px;
				}

				@media (max-width:992px) {
					margin-top: 20px;
					padding-bottom: 15px;
				}

				.back-btn {
					color: var(--primary);

					.fal {
						margin-right: 5px;
					}
				}

				.unit-dt-bl {
					margin-right: 20px;
					margin-top: 15px;

					@media (max-width:992px) {
						margin-top: 0;
					}

					>div {
						font-family: var(--d-font2);
						padding: 0 15px;
						display: inline-block;
						font-size: 18px;
						text-align: center;

						i {
							opacity: .2;

							@media (max-width:992px) {
								margin-right: 7px;
							}

							@media (min-width:993px) {
								margin-bottom: 12px;
							}
						}

						span {

							font-size: 18px;
							text-align: center;

							@media (min-width:993px) {
								display: block;
							}
						}

						@media (max-width:992px) {
							padding: 0 15px 0 7px;
						}
					}
				}
			}




		}



		.unit-head-section {
			margin: 0 0 30px;
			border-bottom: 1px solid #ecf3fd;

			.bg-blue {
				background-color: var(--primary);
			}


		}

		h2 {
			margin-top: 40px;
			font-size: 22px;

			@media (max-width:992px) {
				font-size: 18px;
				margin-top: 20px;
			}

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.col-md-3.bg-white {
			@media (max-width:992px) {
				padding-top: 15px;
			}
		}



		.unit-price-area {
			font-family: var(--d-font2);
			margin-top: 15px;

			@media (max-width:992px) {
				margin-top: 0;
				padding-right: 120px;
				position: relative;

				.btn-booking {
					position: relative;
					right: 0;
					top: -15px;
				}
			}

			h3 {
				font-size: 40px;
				margin-bottom: 0;
				color: var(--secondary);
				line-height: 1;
				font-weight: normal;
				margin: 0;

				@media (max-width:992px) {
					font-size: 28px;
				}

				.ut-crt-price {
					color: var(--black);
				}

				.ut-crt-price {
					display: inline-block;
					margin-top: 0;
					line-height: 1;
				}
			}

			.unit-price-dt {
				font-size: 16px;
				margin-top: 5px;
				display: block;
				color: var(--grey3);

				@media (max-width:992px) {
					font-size: 14px;
				}
			}
		}


		.main-col {

			position: static;
			padding-right: 30px;

			@media (max-width:992px) {
				padding-right: 15px;
			}

			.stars {
				margin-top: 10px;
				margin-right: 0;
				color: var(--secondary);
			}

			.description {
				padding: 15px 15px 5px 15px;
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
					color: var(--primary2);
				}

				>div {
					text-align: center;
				}
			}

			.properties {
				@mixin columnlayout 2;

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				/*blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}*/
			}


		}

		.unit-prop-bl {
			position: relative;
			margin-bottom: 30px;

			.datepicker-trigger {
				display: inline-block;
				border: 2px solid rgba(0, 160, 229, .56);
				box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
				border-radius: 10px;
				overflow: hidden;

				.asd__wrapper {
					border: none;
				}
			}

			&.showLess {
				.unit-pro-bl {
					max-height: 118px;
					overflow: hidden;
					margin-bottom: 15px;

					@media (max-width:992px) {
						margin-bottom: 25px;
					}
				}

				&.showFull {
					.unit-pro-bl {
						max-height: inherit;
						overflow: visible;


					}
				}
			}

			.unit-pro-bl {
				strong {
					font-size: 16px;
				}

				p {
					font-size: 14px;
				}
			}

		}


		.show-hide-btn {
			color: var(--primary);
			font-size: 16px;
			text-transform: uppercase;
			margin-top: 20px;
			font-weight: bold;

			@media (max-width:992px) {
				font-size: 14px;
			}
		}


		.legend-cal {
			text-align: center;
			padding: 0;
			margin: 15px 0;

			ul {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				justify-content: center;
			}

			li {
				display: inline-flex;
				align-items: center;
				min-height: 30px;
				padding-right: 15px;
				margin: 5px 0;

				.day {
					width: 30px;
					height: 30px;
					background-color: #7e7975;
					display: inline-block;
					margin: 0 10px;
					border: 1px solid #e4e7e7;

					@media (max-width:992px) {
						width: 15px;
						height: 15px;
					}

					&.day-free {
						background: var(--white);
					}

					&.day-full {
						background: var(--cal_not_available);
					}

					&.day-end {
						background: linear-gradient(90deg, var(--cal_not_available) 20%, #fff 30%) !important;
					}

					&.day-start {
						background: linear-gradient(90deg, #fff 70%, var(--cal_not_available) 80%) !important;
					}
				}
			}
		}

		.tick-li {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 20px;
			list-style: none;

			li {
				width: 33%;
				padding-left: 30px;
				padding-right: 15px;
				min-height: 30px;
				position: relative;

				@media (max-width:767px) {
					width: 48%;
				}

				@media (max-width:480px) {
					width: 100%;
				}

				&:before {
					content: "\f00c";
					font-family: "Font Awesome 5 Free";
					-webkit-font-smoothing: antialiased;
					font-weight: 600;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: var(--primary);
				}
			}
		}

		@media (min-width:768px) {
			.info-col.noaction {
				position: absolute;
				right: 0;
				top: -41px;

				width: 33.33333333%;
				/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */

				.info-col-header {
					height: 40px;
					padding: 1px;
					background-color: var(--primary);
					color: var(--white);
					text-align: center;

					h2 {
						margin-top: 10px;
						font-size: 18px;
					}
				}

				.info-col-main {
					border: 1px solid #888;
					border-bottom: none;
					width: 100%;
				}

				.subprice {
					width: 100%;
					border: 1px solid #888;
					border-top: none;
				}

				.fav-section {
					margin-top: 20px;
					border-bottom: 1px solid #888 !important;
					padding: 15px;
				}

			}
		}



		.unit-act-side {

			background-color: var(--grey);
			margin: 0 -15px 20px;
			padding: 15px 20px;

			@media (max-width:992px) {
				margin: 0 0 20px;
			}

			.btn+.btn {
				margin-top: 15px;
			}

			.btn {
				font-size: 20px;
				padding: 7px 15px;
				position: relative;
			}

		}



		.fav-section {
			background-color: var(--grey);
			margin: 0 -15px 20px;
			padding: 15px;

			@media (max-width:992px) {
				margin: 0 0 20px;
			}

			.serv-title {
				margin-bottom: 15px;
				padding-bottom: 6px;
				border-bottom: 1px solid var(--primary);
				font-size: 16px;
				color: var(--primary);
				font-family: var(--ft-futura-b);
			}

			.unit-fav-ct {
				display: flex;
				justify-content: space-between;
				margin-bottom: 20px;
				font-size: 15px;

				span {
					display: block;
				}



				.unit-img-bl {
					width: 50%;
				}
			}

		}

		.info-col {


			max-width: 800px;

			.info-col-main {

				padding: 15px 15px 1px 15px;
			}

			.subprice {

				padding: 1px 15px 15px 15px;

				.filters {}


				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
				}

				.flow-buttons {
					button {}
				}
			}




		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		@media (min-width:993px) {
			position: absolute;
			width: 45%;
			right: 0;
			top: 0;
			height: 100%;
		}

		position: relative;
		height: 550px;

		@media (min-width:993px) {
			&:before {
				background-image: url(RESOURCE/img/bg-overlap-map.png);
				height: 100%;
				width: 330px;
				background-size: cover;
				content: "";
				position: absolute;
				z-index: 2;
				top: 0;
			}
		}

		.unit-map {
			height: 100%;
			width: 100%;
		}
	}



	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}

	.subsection {
		.map-center-bl {
			@media (min-width:993px) {
				position: absolute;
				margin: 0 auto;
				left: 50%;
				top: 50px;
				transform: translate(-50%, 0);
			}
		}

		.map-cont-bl {
			padding: 15px;
			display: inline-block;
			max-width: 360px;

			width: 100%;
			background-color: rgba(255, 255, 255, .85);

			@media (min-width:993px) {
				padding: 30px;
				left: 0;
				top: 50px;

				z-index: 999;
			}

			strong {
				margin-bottom: 5px;
				display: block;
			}

			ul {
				li {
					width: 100%;
					display: flex;
					justify-content: space-between;
					list-style: none;
					border-bottom: 1px solid var(--grey2);

					&:last-child {
						border-bottom: none;
					}

					div {
						display: inline-block;
						text-align: right;
					}
				}
			}
		}
	}

	.v-img-gallery {
		max-width: 500px;
		position: relative;
		top: -25px;
		z-index: 5;
		width: 100%;
		display: inline-flex;
		justify-content: flex-end;

		@media (max-width:992px) {
			top: -25px;
			height: auto;
			justify-content: flex-start;
		}

		@media (max-width:380px) {
			top: -45px;
		}

		a {
			position: relative;
			display: inline-block;
			border: 3px solid var(--white);
			box-shadow: 3px 3px 2px rgba(0, 0, 0, .1);

			&:nth-child(n + 4) {
				display: none;
			}

			&:nth-child(3) {
				&:before {
					z-index: 5;
					content: "+ mehr";
					position: absolute;
					color: var(--white);
					background-color: rgba(0, 0, 0, 0.5);
					width: 100%;
					height: 100%;
					text-align: center;
					display: inline-block;
					padding-top: 25%;
					font-weight: 700;
					font-size: 20px;
				}
			}
		}

		.img-thumbnail {
			border: none;
			padding: 0;
			width: 100%;

		}

	}

	.unit-title-bx {
		h1 {
			font-size: 42px;
			padding-right: 15px;

			@media (max-width:992px) {
				font-size: 28px;
			}

			small {
				font-family: var(--d-font);
				font-size: 21px;
				color: #ccc;
				position: relative;
				top: -10px;

				@media (max-width:992px) {
					font-size: 16px;
				}
			}
		}
	}

	.unit-tbl-row {
		td {
			border-top: 1px solid var(--grey2);
			padding: 15px 0;

			&:first-child {
				font-family: var(--dfont2);
				width: 25%;
			}
		}
	}


	@media all and (max-width:992px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

	}

	.properties {
		.prop {
			color: var(--black2);
			font-size: 18px;
			margin-bottom: 25px;
			position: relative;
			padding-left: 30px;

			@media (max-width:992px) {
				font-size: 13px;
				margin-bottom: 8px;
			}

			&:before {
				content: "\f00c";
				color: var(--secondary);
				position: absolute;
				left: 0;
				font-family: Font Awesome\ 5 Pro !important;
				top: 0;
			}
		}
	}




	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}

}


.unit-form-section {
	background-image: url(RESOURCE/img/banner1.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	padding: 115px 0 250px;
	position: relative;

	@media (max-width:992px) {
		padding: 50px 0 150px;
	}

	&:after {
		background: rgb(42, 82, 152);
		background: linear-gradient(318deg, rgba(17, 17, 17, 0.7) 0%, rgba(17, 17, 17, 0.6) 100%);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		content: "";
	}

	.container {
		position: relative;
		z-index: 1;

		>.row {
			margin: 0;

			>.col-md-6 {
				padding: 0;
			}
		}
	}

	.grey-text {
		opacity: .25;
		color: var(--white);
	}

	h2 {
		color: var(--white);
	}

	.unit-left-cont {
		padding: 35px 50px;
		background-color: var(--secondary);
		margin-top: 100px;
		box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
		width: 101%;
		position: relative;
		left: 1%;
		z-index: 1;

		@media (max-width:992px) {
			margin-top: 55px;
			padding: 40px 30px;
			width: inherit;
			left: 0;
			margin-left: 20px;
		}

		@media (max-width:380px) {
			padding: 30px 20px;
		}


		h3 {
			font-size: 36px;
			font-weight: bold;
			color: var(--grey2);
			margin: 0 0 30px;

			@media (max-width:992px) {
				font-size: 24px;
			}
		}

		p {
			font-size: 14px;
			color: var(--grey2);
			margin-top: 30px;
			margin-bottom: 0;
			line-height: 2;

			@media (max-width:992px) {
				font-size: 12px;
			}
		}

		.icon-bl {
			position: relative;
			padding-left: 65px;
			min-height: 50px;
			margin-bottom: 10px;
			display: inline-flex;
			align-items: center;
			font-family: var(--d-font3);

			@media (max-width:380px) {
				padding-left: 50px;
				min-height: 40px;
			}



			&:hover {
				span {
					color: var(--primary);
				}

				i {
					background-color: var(--primary);
				}
			}

			i {
				position: absolute;
				background-color: #044e71;
				font-size: 18px;
				width: 50px;
				height: 50px;
				display: inline-block;
				text-align: center;
				line-height: 50px;
				color: var(--white);
				border-radius: 50%;
				left: 0;
				top: 0;

				@media (max-width:380px) {
					font-size: 16px;
					width: 40px;
					height: 40px;
					line-height: 40px;
				}
			}

			span {
				color: var(--white);
				font-size: 18px;

				@media (max-width:380px) {
					font-size: 14px;
				}
			}

		}

		.fix-contact {
			position: absolute;
			width: 50%;
			right: 0;
			top: 0;
		}
	}



	.contact-form {
		box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
		background-color: var(--white);
		padding: 35px 50px 70px;
		width: 101%;
		position: relative;
		left: -1%;

		@media (max-width:992px) {
			padding: 25px 30px;
			width: inherit;
			left: 0;
			margin-left: 40px;
			position: relative;
			top: -15px;
			padding-bottom: 60px;
			z-index: 2;
		}

		label {
			color: var(--black);
			font-weight: bold;
			font-size: 14px;
			text-transform: uppercase;
			margin-bottom: 0;
			padding-bottom: 10px;

			&:before {
				top: 2px;
			}
		}

		.checkbox {
			label {
				text-transform: inherit;
				font-weight: normal;
			}

			a {
				color: var(--black);
				font-size: 14px;
				position: relative;
				top: -2px;
			}
		}

		.conact-btn {
			position: absolute;
			left: 0;
			width: 100%;
			text-align: center;
			bottom: -20px;

			.btn {
				padding: 11px 30px 15px;
				box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
				font-size: 20px;

				@media (max-width:992px) {
					font-size: 18px;
				}
			}
		}

		.form-control {
			border: none;
			border-bottom: 2px solid #f2f2f2;
			text-transform: uppercase;
			margin-bottom: 30px;
			border-radius: 0;
			box-shadow: inherit;
			padding: 0;
		}
	}
}

.time-li {
	position: relative;
	overflow: hidden;
	padding-bottom: 50px;
	margin-left: 40px;

	&:before {
		content: "";
		width: 2px;
		background-color: #dddddd;
		height: 100%;
		position: absolute;
		left: 7px;
		top: 10px;

		@media (max-width:992px) {
			left: 4px;
		}
	}

	li {
		position: relative;
		list-style: none;
		margin-bottom: 20px;
		padding-left: 180px;
		margin-bottom: 22px;

		@media (max-width:992px) {
			padding-left: 140px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		span {
			font-size: 18px;

			@media (max-width:992px) {
				font-size: 18px;
			}
		}

		&:before {
			content: "";
			width: 15px;
			height: 15px;
			display: inline-block;
			border-radius: 20px;
			background-color: #dddddd;
			position: absolute;
			left: 0;
			top: 9px;

			@media (max-width:992px) {
				width: 10px;
				height: 10px;
			}
		}

		strong {
			background-color: var(--secondary);
			color: var(--white);
			font-size: 18px;
			width: 100px;
			padding: 6px 12px 8px;
			border-radius: 15px;
			display: inline-block;
			line-height: 1;
			text-align: center;
			position: absolute;
			left: 40px;
			font-family: var(--d-font2);
			font-weight: normal;

			@media (max-width:992px) {
				font-size: 16px;
				width: 80px;
				left: 45px;
			}

			span {
				font-size: 18px;

				@media (max-width:992px) {
					font-size: 16px;
				}
			}
		}
	}
}

.facility-view {
	.unit-proposals {
		.more-units-btn {
			text-align: center;
		}
	}
}