:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--mobileMenuImg: url(RESOURCE/img/bg-menu-black.jpg);
	--navbarbreak: 768px;
	--primary: #363538;
	--secondary: #666666;
	--black: #333;
	--blue-grey: #363538;
	--blue-black: #191919;
	--d-font: 'DINRoundPro', sans-serif;
	--d-font2: 'DINRoundPro-Medi', sans-serif;
	--white: #fff;
	--d-blue: #033848;
	--d-blue2: #03364d;
	--h1-font: 'Julius Sans One', sans-serif;
	--grey: #e5e5e5;
	--d-grey: #727272;
	--blue3: #405b79;
	--f-nunito: 'Nunito', sans-serif;
	--blue4: #666666;
	--li-blue: #4d4d4d;
	--grey2: #f5f5f5;
	--red: #f55454;
	--grey3: #999999;
	--d-font3: 'Ubuntu', sans-serif;
	--grey4: #f9f9f9;

	/*Calendar colors*/
	--cal_not_available: #cd7898;
	--disabled: rgba(86, 90, 92, .5);
	--available: #fff;
	--selected: #062e5f;
	--selection_range: #4D6E96;
	--selected_border: #fff;
}